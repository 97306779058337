export const logoNegative = ['500 134', `
  <title>Carwise</title>
  <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
\t viewBox="0 0 100 64" style="enable-background:new 0 0 100 64;" xml:space="preserve">
<style type="text/css">
\t.st0{fill:#F50000;}
\t.st1{fill:#252525;}
</style>
<g id="CarWize_LOGO_2_">
\t<g>
\t\t<path class="st0" d="M90.09,18.08c-1.39-0.8-3.05-1.16-4.76-1.02c-1.02-1.21-3.7-4.22-6.41-5.67c-4.19-2.23-9.89-4.54-24.66-3.7
\t\t\tC45.04,8.22,40.53,9.2,36.37,11.6c-2.36,1.36-5.44,3.45-7.15,4.62c-0.04-0.04-0.09-0.09-0.13-0.13c-0.41-0.35-1.15-0.68-2.35-0.29
\t\t\tc-1.56,0.51-2.64,1.49-2.9,2.64c-0.06,0.3-0.07,0.6-0.03,0.88c-0.76,0.15-1.7,0.2-2.57,0.08l-0.29-0.04
\t\t\tc-1.59-0.23-4.27-0.6-7.41,0.58c-2.47,0.93-4.38,2.41-5.71,4.41C9.78,11.01,27.94,0.54,50.06,0.54
\t\t\tC68.56,0.54,84.3,7.87,90.09,18.08z"/>
\t\t<g>
\t\t\t<g>
\t\t\t\t<path class="st1" d="M66.58,11.26c-0.39-0.11-3.28-0.57-7.96-0.64c-4.68-0.07-12.13,0.04-14.86,0.22
\t\t\t\t\tc-2.73,0.18-4.46,0.48-5.48,1.02c-1.02,0.54-3.66,2.14-5.64,3.44c-1.98,1.3-3.39,2.25-4.01,2.76c0,0,0.03-0.29-0.07-0.63
\t\t\t\t\tc-0.09-0.33-0.22-0.54-0.72-0.6s-1,0.14-1.52,0.43c-0.52,0.29-1.02,0.69-1.21,1.16c-0.23,0.57-0.2,1.04,0.38,1.6
\t\t\t\t\tc-0.95,0.38-2,0.49-3.39,1.02c-2.46,0.95-4.45,1.66-7.18,5.73c0.05-0.06,1.7-1.95,4.01-3.44c1.77-1.14,4.07-1.89,5.57-2.26
\t\t\t\t\tc3.65-0.9,8.27-2.01,15.57-2.01c3.84,0,8.97,0.04,12.87,0.16c3.91,0.12,8.65,0.52,9.94,0.7c0.07,0.01,0.13,0.02,0.19,0.02
\t\t\t\t\tc0.43,0.07,1.82,0.34,2.83,0.9c-0.76-0.64-1.41-1.04-1.58-1.15c0.05-0.03,0.1-0.06,0.14-0.1c0.38-0.29,1.22-2.48,1.79-4.76
\t\t\t\t\tc0.57-2.29,0.44-1.83,0.67-2.79C67.03,11.73,66.98,11.37,66.58,11.26z M35.23,14.62c-0.91,0.63-2.19,1.65-2.04,2.26
\t\t\t\t\tc0.15,0.57,0.36,0.77,1.58,1.02c0.08,0.02,0.07,0.14-0.01,0.14c-1.54,0.05-3.29,0.18-4.81,0.39c-0.07,0.01-0.11-0.08-0.06-0.13
\t\t\t\t\tc0.9-0.8,3.9-3.01,5.22-3.82C35.21,14.41,35.32,14.55,35.23,14.62z"/>
\t\t\t\t<path class="st1" d="M68.67,29.72c0.13-0.13-0.23-1.04-0.31-1.06c-0.13-0.02-0.58,0.11-0.65,0.2c-0.1,0.11,0.12,1.06,0.32,1.06
\t\t\t\t\tC68.17,29.92,68.54,29.85,68.67,29.72z"/>
\t\t\t\t<path class="st1" d="M67.66,29.95c0.13-0.13-0.23-1.04-0.31-1.06c-0.13-0.02-0.58,0.11-0.65,0.2c-0.1,0.11,0.12,1.06,0.32,1.06
\t\t\t\t\tC67.15,30.14,67.52,30.07,67.66,29.95z"/>
\t\t\t\t<path class="st1" d="M65.99,30.37c0.14,0,0.52-0.07,0.65-0.19c0.13-0.13-0.23-1.04-0.31-1.06c-0.13-0.02-0.58,0.11-0.65,0.2
\t\t\t\t\tC65.57,29.43,65.8,30.37,65.99,30.37z"/>
\t\t\t\t<path class="st1" d="M92.21,22.64c-0.67-3.14-4.33-4.85-7.38-4.35c-2.03-2.64-5.15-5.45-8.29-6.84
\t\t\t\t\tc-3.02-1.34-6.57-2.13-9.84-2.51C62.9,8.5,58.89,8.7,55.08,8.9c-3.56,0.19-6.92,0.44-10.47,0.93c-0.43,0.06-0.44,0.48-0.1,0.44
\t\t\t\t\tc1.12-0.13,3.51-0.24,4.42-0.27c1.7-0.06,4.86-0.12,8.43-0.17c3.57-0.04,7.5,0.25,9.1,0.62c1.6,0.37,2.33,0.85,2.06,1.97
\t\t\t\t\tc-0.16,0.65-0.63,2.92-1.11,6.03c0.72-2.63,1.52-5.26,2.13-5.98c0.9-1.07,3.07-0.47,4.16-0.2c3.2,0.8,6.05,2.65,7.02,5.96
\t\t\t\t\tc0.15,0.5,0.18,0.87,0.15,1.14c-3.63,1.04-8.77,2.49-10.28,2.64c-0.23,0.02-0.45,0.04-0.65,0.06c-0.24,0.02-0.38-0.28-0.19-0.44
\t\t\t\t\tc0.65-0.58,1.36-0.72,2.4-0.92c0.73-0.05,2-0.12,2.4-0.13c0.48-0.01,1.56-0.14,1.89-0.85c0.15-0.33,0.12-0.94,0.1-1.25
\t\t\t\t\tc-0.02-0.3-0.17-0.67-0.54-0.9c-0.37-0.23-1.5-0.66-2.65-0.8c-1.15-0.14-1.69,0.55-2.05,1c-0.23,0.28-0.48,0.68-0.67,1.13
\t\t\t\t\tc-0.1,0.24-0.24,0.46-0.41,0.64c-0.95,0.98-2.08,1.64-3.41,1.98c-0.45,0.11-0.92,0.09-1.36-0.05c-0.49-0.16-1.05-0.35-1.7-0.58
\t\t\t\t\tc-4.07-1.46-9.98,1.12-12.49,2.67c-2.51,1.56-11.46,6.7-16.16,6.88c1.94,0.02,4.04-0.23,5.67-0.62
\t\t\t\t\tc-0.02,0.07-0.01,0.14,0.05,0.21c0.19,0.21,1.04,0.76,2.06,0.81c1.02,0.05,7.88-0.75,9.01-0.94c1.13-0.19,1.51-0.52,2.27-1.18
\t\t\t\t\tc0.76-0.65,1.58-1.98,1.82-2.39c0.2-0.35,0.15-0.64-0.4-0.81c-0.56-0.17-1.98-0.19-3.52-0.07c-1,0.08-1.45,0.18-2.5,0.57
\t\t\t\t\tc0.99-0.5,1.83-0.97,2.35-1.31c1.88-1.26,7.1-4.07,11.5-2.62c4.48,1.48,5.85,1.35,9.1,0.75c1.81-0.33,10.2-2.82,11.99-3.21
\t\t\t\t\tc3.26-0.71,5.12,0.6,6.14,2.36c1.33,2.29,1,6.77,0.36,8.5C91.99,28.28,92.93,25.99,92.21,22.64z"/>
\t\t\t\t<path class="st1" d="M11.39,26.81c-0.06-0.14-0.12-0.28-0.18-0.41c-0.04-0.1-0.07-0.19-0.1-0.27c-0.53-1.66-0.69-2.46,1.11-3.61
\t\t\t\t\tc2.29-1.38,5.42-2.13,8.86-1.95c-6.81-1.34-14.58,2.63-13.28,9.89c0.05-0.35,0.12-0.75,0.2-1.15c0.03,0.03,0.06,0.06,0.1,0.08
\t\t\t\t\tc0.23,0.13,1.7,0.82,2.01,0.95c0.31,0.13,0.67,0.13,1.01-0.11c0.35-0.24,0.76-0.51,0.89-0.61c0.06-0.04,0.14-0.12,0.19-0.24
\t\t\t\t\tc0.69,0.55,1.49,0.79,2.63,1.13C13,29.58,11.9,27.87,11.39,26.81z"/>
\t\t\t\t<path class="st1" d="M26.57,30.78l-0.23,0c-0.53,0.01-3.23,0.03-3.23,0.03l-0.07,0l-0.06,0l-0.23,0.01
\t\t\t\t\tc-1.24,0.02-2.5,0.03-3.74,0.14l1.87,0.1c0.62,0.03,1.25,0.07,1.87,0.08l0.23,0.01l0.06,0l0.05,0c0.03,0,0.06,0.01,0.1,0.01
\t\t\t\t\tc0.13,0.02,0.24,0.08,0.35,0.16c0.21,0.16,0.37,0.41,0.51,0.67c0.07,0.13,0.13,0.27,0.2,0.41c0.03,0.07,0.07,0.14,0.11,0.22
\t\t\t\t\tc0.01,0.02,0.02,0.04,0.03,0.06c0.01,0.02,0.02,0.04,0.04,0.06c0.01,0.01,0.01,0.02,0.02,0.03c0.01,0.01,0.02,0.02,0.03,0.04
\t\t\t\t\tc0.01,0.01,0.03,0.03,0.04,0.04c0.02,0.02,0.05,0.03,0.07,0.05c0.04,0.01,0.08,0.02,0.12,0.02c0.04,0,0.09-0.02,0.11-0.03
\t\t\t\t\tc0.01-0.01,0.03-0.02,0.04-0.02c0.01-0.01,0.02-0.01,0.03-0.02c0.01-0.01,0.03-0.03,0.04-0.04c0.02-0.02,0.04-0.05,0.05-0.07
\t\t\t\t\tc0.01-0.02,0.03-0.04,0.04-0.06c0.04-0.08,0.08-0.15,0.11-0.22c0.07-0.14,0.13-0.28,0.2-0.41c0.14-0.26,0.3-0.51,0.5-0.68
\t\t\t\t\tc0.1-0.08,0.21-0.14,0.33-0.17c0.03-0.01,0.06-0.01,0.09-0.02c0.03,0,0.06,0,0.1-0.01l0.23,0c0.62-0.01,1.25,0,1.87-0.01
\t\t\t\t\tc1.25-0.01,2.5-0.03,3.75-0.08c-1.24-0.12-2.49-0.2-3.74-0.25C27.82,30.81,27.19,30.79,26.57,30.78z"/>
\t\t\t\t<path class="st1" d="M88.66,23.83c-1.45,1.07-1.92,2.97-2.52,4.66c-0.19,0.52-0.41,1.06-0.86,1.38
\t\t\t\t\tc-0.62,0.45-1.46,0.36-2.21,0.27c-4.8-0.59-12.89-0.86-17.28,1.04c1.72-0.45,4.94-0.94,8.24-0.97c3.58-0.03,7.24,0.42,8.75,0.58
\t\t\t\t\tc0.66,0.07,1.4,0.18,2.05,0.04c1.02-0.22,1.49-1.1,1.83-2.04c0.56-1.51,0.81-2.6,1.19-3.36c0.39-0.77,0.95-1.25,1.05-1.34
\t\t\t\t\tc0.21-0.18,0.5-0.35,0.76-0.4c0.33-0.07,0.68-0.06,0.96,0.1c-0.22-0.3-0.61-0.44-0.98-0.39C89.3,23.46,88.95,23.62,88.66,23.83z
\t\t\t\t\t"/>
\t\t\t</g>
\t\t</g>
\t</g>
\t<g>
\t\t<path class="st1" d="M26.7,61.33c-0.03,0-0.05,0.01-0.08,0.02c-0.02,0.01-0.05,0.03-0.07,0.05c-0.07,0.06-0.14,0.12-0.21,0.16
\t\t\tc-0.07,0.04-0.15,0.08-0.22,0.1c-0.08,0.03-0.16,0.05-0.26,0.06c-0.09,0.01-0.2,0.02-0.31,0.02c-0.19,0-0.37-0.04-0.54-0.11
\t\t\tc-0.17-0.07-0.31-0.18-0.44-0.31c-0.12-0.14-0.22-0.3-0.29-0.5c-0.07-0.2-0.11-0.43-0.11-0.68c0-0.25,0.04-0.47,0.11-0.67
\t\t\tc0.07-0.2,0.17-0.37,0.3-0.5c0.13-0.14,0.28-0.25,0.46-0.32c0.18-0.07,0.38-0.11,0.59-0.11c0.18,0,0.33,0.02,0.45,0.06
\t\t\tc0.12,0.04,0.22,0.08,0.29,0.12c0.08,0.04,0.14,0.09,0.19,0.12c0.05,0.04,0.1,0.06,0.15,0.06c0.05,0,0.08-0.01,0.11-0.03
\t\t\tc0.03-0.02,0.05-0.04,0.06-0.07l0.28-0.39c-0.18-0.18-0.4-0.32-0.65-0.43c-0.25-0.11-0.55-0.16-0.88-0.16
\t\t\tc-0.35,0-0.66,0.06-0.95,0.17c-0.29,0.11-0.53,0.27-0.74,0.48c-0.2,0.21-0.36,0.45-0.48,0.73c-0.11,0.28-0.17,0.6-0.17,0.94
\t\t\tc0,0.34,0.05,0.65,0.15,0.93c0.1,0.29,0.25,0.53,0.44,0.74c0.19,0.2,0.42,0.36,0.69,0.48c0.27,0.12,0.57,0.17,0.91,0.17
\t\t\tc0.38,0,0.7-0.06,0.97-0.18c0.27-0.12,0.5-0.3,0.68-0.52l-0.33-0.36C26.79,61.35,26.75,61.33,26.7,61.33z"/>
\t\t<path class="st1" d="M30.82,59.87c-0.05-0.15-0.13-0.28-0.23-0.39s-0.23-0.2-0.38-0.26c-0.15-0.06-0.32-0.09-0.5-0.09
\t\t\tc-0.52,0-0.97,0.17-1.34,0.51l0.14,0.25c0.02,0.04,0.05,0.07,0.09,0.1c0.04,0.03,0.08,0.04,0.13,0.04c0.06,0,0.12-0.02,0.17-0.04
\t\t\tc0.05-0.03,0.11-0.06,0.17-0.1c0.06-0.04,0.13-0.07,0.22-0.1c0.08-0.03,0.19-0.04,0.31-0.04c0.17,0,0.31,0.05,0.4,0.16
\t\t\tc0.09,0.11,0.14,0.27,0.14,0.48v0.19c-0.37,0-0.67,0.03-0.92,0.09c-0.25,0.06-0.44,0.14-0.59,0.24c-0.15,0.1-0.26,0.2-0.32,0.32
\t\t\tc-0.06,0.12-0.1,0.24-0.1,0.36c0,0.15,0.02,0.27,0.07,0.38c0.05,0.11,0.11,0.2,0.19,0.27c0.08,0.07,0.18,0.13,0.3,0.17
\t\t\ts0.24,0.05,0.38,0.05c0.12,0,0.22-0.01,0.31-0.03c0.09-0.02,0.18-0.05,0.27-0.08c0.08-0.04,0.16-0.09,0.24-0.14
\t\t\tc0.08-0.06,0.16-0.12,0.24-0.19l0.07,0.23c0.02,0.07,0.05,0.11,0.09,0.13c0.04,0.02,0.1,0.03,0.17,0.03h0.35v-2.04
\t\t\tC30.91,60.19,30.88,60.02,30.82,59.87z M30.15,61.58c-0.06,0.06-0.11,0.11-0.17,0.15c-0.05,0.04-0.11,0.08-0.17,0.11
\t\t\tc-0.06,0.03-0.12,0.05-0.19,0.07c-0.07,0.01-0.14,0.02-0.22,0.02c-0.13,0-0.23-0.03-0.32-0.09c-0.08-0.06-0.12-0.16-0.12-0.29
\t\t\tc0-0.07,0.02-0.13,0.06-0.19c0.04-0.06,0.1-0.11,0.2-0.16c0.09-0.05,0.21-0.08,0.37-0.11c0.15-0.03,0.34-0.04,0.57-0.04V61.58z"/>
\t\t<path class="st1" d="M34.36,61.05c-0.05-0.09-0.1-0.17-0.18-0.24c-0.07-0.07-0.16-0.12-0.25-0.16c-0.09-0.04-0.19-0.08-0.29-0.12
\t\t\tc-0.1-0.03-0.19-0.06-0.29-0.09c-0.09-0.03-0.18-0.06-0.25-0.1c-0.07-0.04-0.13-0.08-0.18-0.12c-0.04-0.05-0.07-0.1-0.07-0.17
\t\t\tc0-0.1,0.04-0.19,0.13-0.25c0.09-0.07,0.21-0.1,0.36-0.1c0.1,0,0.18,0.01,0.26,0.03c0.07,0.02,0.14,0.04,0.19,0.07
\t\t\tc0.06,0.03,0.11,0.05,0.15,0.07c0.04,0.02,0.08,0.03,0.12,0.03c0.04,0,0.07-0.01,0.09-0.02c0.02-0.01,0.04-0.04,0.07-0.07
\t\t\tl0.18-0.28c-0.12-0.12-0.27-0.22-0.46-0.29c-0.18-0.07-0.39-0.11-0.61-0.11c-0.2,0-0.37,0.03-0.53,0.08
\t\t\tc-0.15,0.05-0.28,0.12-0.38,0.21c-0.1,0.09-0.18,0.19-0.23,0.31c-0.05,0.12-0.08,0.25-0.08,0.38c0,0.14,0.02,0.26,0.07,0.36
\t\t\ts0.11,0.19,0.18,0.26c0.07,0.07,0.16,0.13,0.25,0.17c0.1,0.05,0.19,0.09,0.29,0.12c0.1,0.03,0.2,0.06,0.29,0.09
\t\t\tc0.09,0.03,0.18,0.06,0.25,0.1c0.07,0.04,0.13,0.08,0.18,0.12c0.05,0.05,0.07,0.11,0.07,0.19c0,0.05-0.01,0.1-0.03,0.15
\t\t\tc-0.02,0.05-0.05,0.09-0.09,0.12c-0.04,0.04-0.1,0.06-0.16,0.09c-0.07,0.02-0.14,0.03-0.24,0.03c-0.12,0-0.21-0.01-0.29-0.04
\t\t\tc-0.08-0.03-0.14-0.06-0.2-0.09c-0.06-0.03-0.11-0.06-0.15-0.09c-0.04-0.03-0.09-0.04-0.14-0.04c-0.05,0-0.09,0.01-0.12,0.03
\t\t\tc-0.03,0.02-0.06,0.05-0.08,0.08l-0.18,0.29c0.06,0.06,0.14,0.11,0.22,0.16c0.08,0.05,0.17,0.09,0.27,0.13
\t\t\tc0.1,0.03,0.2,0.06,0.3,0.08c0.11,0.02,0.21,0.03,0.33,0.03c0.21,0,0.39-0.03,0.55-0.08c0.16-0.05,0.29-0.13,0.4-0.22
\t\t\tc0.11-0.1,0.19-0.21,0.25-0.34c0.06-0.13,0.09-0.27,0.09-0.43C34.42,61.25,34.4,61.14,34.36,61.05z"/>
\t\t<path class="st1" d="M38.41,59.86c-0.05-0.15-0.12-0.28-0.21-0.39c-0.09-0.11-0.2-0.19-0.34-0.25c-0.14-0.06-0.29-0.09-0.47-0.09
\t\t\tc-0.2,0-0.38,0.04-0.53,0.11c-0.15,0.07-0.29,0.17-0.41,0.29v-1.79h-0.78v4.67h0.78v-2.33c0.1-0.11,0.22-0.19,0.33-0.25
\t\t\tc0.12-0.06,0.24-0.09,0.38-0.09c0.18,0,0.32,0.05,0.41,0.16c0.09,0.11,0.14,0.26,0.14,0.46v2.05h0.78v-2.05
\t\t\tC38.48,60.17,38.46,60.01,38.41,59.86z"/>
\t\t<path class="st1" d="M40.6,61.79c-0.02-0.06-0.06-0.11-0.1-0.15c-0.04-0.04-0.09-0.08-0.15-0.1c-0.06-0.02-0.12-0.04-0.19-0.04
\t\t\tc-0.07,0-0.13,0.01-0.19,0.04c-0.06,0.03-0.11,0.06-0.15,0.1c-0.04,0.04-0.08,0.09-0.1,0.15c-0.02,0.06-0.04,0.12-0.04,0.19
\t\t\tc0,0.07,0.01,0.13,0.04,0.19c0.02,0.06,0.06,0.11,0.1,0.15c0.04,0.04,0.09,0.08,0.15,0.1c0.06,0.02,0.12,0.04,0.19,0.04
\t\t\tc0.06,0,0.13-0.01,0.19-0.04c0.06-0.02,0.11-0.06,0.15-0.1c0.04-0.04,0.08-0.09,0.1-0.15c0.02-0.06,0.04-0.12,0.04-0.19
\t\t\tC40.64,61.91,40.63,61.85,40.6,61.79z"/>
\t\t<path class="st1" d="M46.44,60.5c-0.06-0.13-0.14-0.24-0.24-0.33c-0.1-0.09-0.21-0.17-0.34-0.23c-0.13-0.06-0.25-0.11-0.39-0.16
\t\t\tc-0.13-0.05-0.26-0.09-0.38-0.13c-0.13-0.04-0.24-0.09-0.34-0.14c-0.1-0.05-0.18-0.11-0.24-0.18c-0.06-0.07-0.09-0.16-0.09-0.27
\t\t\tc0-0.08,0.02-0.16,0.04-0.23c0.03-0.07,0.07-0.13,0.13-0.18c0.06-0.05,0.13-0.09,0.22-0.12c0.09-0.03,0.19-0.04,0.31-0.04
\t\t\tc0.13,0,0.24,0.02,0.34,0.05c0.09,0.03,0.18,0.07,0.25,0.11c0.07,0.04,0.13,0.08,0.19,0.11c0.05,0.03,0.1,0.05,0.14,0.05
\t\t\ts0.08-0.01,0.11-0.03c0.03-0.02,0.06-0.05,0.08-0.1l0.21-0.4c-0.16-0.15-0.35-0.27-0.58-0.35c-0.23-0.08-0.47-0.13-0.74-0.13
\t\t\tc-0.24,0-0.45,0.04-0.63,0.11c-0.18,0.07-0.34,0.17-0.47,0.29c-0.13,0.12-0.22,0.26-0.29,0.42c-0.07,0.16-0.1,0.32-0.1,0.48
\t\t\tc0,0.21,0.03,0.38,0.09,0.52c0.06,0.14,0.14,0.26,0.24,0.35c0.1,0.09,0.21,0.17,0.34,0.23c0.13,0.06,0.25,0.11,0.38,0.15
\t\t\tc0.13,0.04,0.26,0.08,0.38,0.12c0.13,0.04,0.24,0.08,0.34,0.13c0.1,0.05,0.18,0.12,0.24,0.19c0.06,0.08,0.09,0.17,0.09,0.29
\t\t\tc0,0.21-0.07,0.38-0.2,0.49s-0.32,0.17-0.56,0.17c-0.16,0-0.29-0.02-0.4-0.06c-0.11-0.04-0.21-0.09-0.29-0.14
\t\t\tc-0.08-0.05-0.16-0.1-0.22-0.14c-0.06-0.04-0.12-0.06-0.17-0.06c-0.04,0-0.08,0.01-0.11,0.03c-0.03,0.02-0.06,0.05-0.08,0.07
\t\t\tl-0.25,0.41c0.09,0.09,0.19,0.17,0.3,0.24c0.11,0.07,0.23,0.14,0.36,0.19s0.26,0.09,0.4,0.12c0.14,0.03,0.28,0.04,0.43,0.04
\t\t\tc0.25,0,0.47-0.04,0.67-0.12c0.19-0.08,0.36-0.18,0.5-0.31c0.13-0.13,0.24-0.29,0.31-0.47c0.07-0.18,0.11-0.37,0.11-0.58
\t\t\tC46.53,60.79,46.5,60.63,46.44,60.5z"/>
\t\t<path class="st1" d="M50.21,59.87c-0.05-0.15-0.13-0.28-0.23-0.39s-0.23-0.2-0.38-0.26c-0.15-0.06-0.32-0.09-0.5-0.09
\t\t\tc-0.52,0-0.97,0.17-1.34,0.51l0.14,0.25c0.02,0.04,0.05,0.07,0.09,0.1c0.04,0.03,0.08,0.04,0.13,0.04c0.06,0,0.12-0.02,0.17-0.04
\t\t\tc0.05-0.03,0.11-0.06,0.17-0.1c0.06-0.04,0.13-0.07,0.22-0.1c0.08-0.03,0.19-0.04,0.31-0.04c0.17,0,0.31,0.05,0.4,0.16
\t\t\tc0.09,0.11,0.14,0.27,0.14,0.48v0.19c-0.37,0-0.67,0.03-0.92,0.09c-0.25,0.06-0.45,0.14-0.59,0.24c-0.15,0.1-0.26,0.2-0.32,0.32
\t\t\tc-0.06,0.12-0.1,0.24-0.1,0.36c0,0.15,0.02,0.27,0.07,0.38c0.05,0.11,0.11,0.2,0.19,0.27c0.08,0.07,0.18,0.13,0.3,0.17
\t\t\ts0.24,0.05,0.38,0.05c0.12,0,0.22-0.01,0.31-0.03c0.09-0.02,0.18-0.05,0.27-0.08c0.08-0.04,0.16-0.09,0.24-0.14
\t\t\tc0.08-0.06,0.16-0.12,0.24-0.19l0.07,0.23c0.02,0.07,0.05,0.11,0.09,0.13c0.04,0.02,0.1,0.03,0.17,0.03h0.35v-2.04
\t\t\tC50.29,60.19,50.26,60.02,50.21,59.87z M49.53,61.58c-0.06,0.06-0.11,0.11-0.17,0.15c-0.05,0.04-0.11,0.08-0.17,0.11
\t\t\tc-0.06,0.03-0.12,0.05-0.19,0.07c-0.07,0.01-0.14,0.02-0.22,0.02c-0.13,0-0.23-0.03-0.32-0.09c-0.08-0.06-0.12-0.16-0.12-0.29
\t\t\tc0-0.07,0.02-0.13,0.06-0.19c0.04-0.06,0.1-0.11,0.2-0.16c0.09-0.05,0.21-0.08,0.36-0.11c0.15-0.03,0.34-0.04,0.57-0.04V61.58z"/>
\t\t<path class="st1" d="M52.61,58.69c0.03-0.08,0.06-0.15,0.11-0.19c0.05-0.05,0.11-0.08,0.18-0.1c0.07-0.02,0.16-0.03,0.25-0.03
\t\t\tc0.07,0,0.13-0.01,0.18-0.02c0.05-0.02,0.08-0.05,0.09-0.11l0.02-0.39c-0.13-0.04-0.27-0.06-0.41-0.06c-0.19,0-0.35,0.03-0.5,0.08
\t\t\tc-0.15,0.05-0.27,0.13-0.38,0.24c-0.1,0.1-0.18,0.23-0.24,0.37c-0.05,0.15-0.08,0.31-0.08,0.5v0.24h-0.48v0.32
\t\t\tc0,0.06,0.02,0.1,0.05,0.13c0.04,0.03,0.09,0.05,0.15,0.06l0.28,0.04v2.64h0.78v-2.64h0.82v-0.55h-0.84V59
\t\t\tC52.57,58.88,52.58,58.78,52.61,58.69z"/>
\t\t<path class="st1" d="M57.15,60.88c0.02-0.01,0.04-0.02,0.05-0.05c0.01-0.02,0.02-0.05,0.03-0.09c0.01-0.04,0.01-0.09,0.01-0.15
\t\t\tc0-0.23-0.03-0.44-0.1-0.62c-0.07-0.18-0.17-0.33-0.29-0.46c-0.12-0.12-0.27-0.22-0.44-0.29c-0.17-0.06-0.36-0.1-0.56-0.1
\t\t\tc-0.24,0-0.46,0.04-0.65,0.13c-0.19,0.08-0.35,0.2-0.49,0.34c-0.13,0.14-0.24,0.31-0.31,0.51c-0.07,0.19-0.11,0.4-0.11,0.62
\t\t\tc0,0.28,0.04,0.53,0.12,0.75c0.08,0.21,0.19,0.4,0.34,0.54c0.14,0.15,0.31,0.26,0.5,0.33c0.19,0.08,0.4,0.11,0.63,0.11
\t\t\tc0.11,0,0.23-0.01,0.35-0.03c0.12-0.02,0.24-0.05,0.36-0.09c0.12-0.04,0.23-0.09,0.33-0.16c0.11-0.07,0.2-0.15,0.29-0.25
\t\t\tl-0.23-0.28c-0.02-0.02-0.04-0.04-0.06-0.05c-0.02-0.01-0.05-0.02-0.08-0.02c-0.05,0-0.1,0.02-0.15,0.04
\t\t\tc-0.05,0.03-0.11,0.06-0.18,0.1s-0.15,0.07-0.24,0.1c-0.09,0.03-0.2,0.04-0.33,0.04c-0.26,0-0.46-0.08-0.62-0.23
\t\t\tc-0.16-0.16-0.24-0.4-0.27-0.73h1.99C57.1,60.89,57.13,60.89,57.15,60.88z M55.09,60.42c0.04-0.23,0.12-0.42,0.24-0.54
\t\t\tc0.13-0.13,0.31-0.19,0.53-0.19c0.11,0,0.22,0.02,0.3,0.06c0.09,0.04,0.16,0.09,0.21,0.16c0.06,0.07,0.1,0.15,0.13,0.23
\t\t\tc0.03,0.09,0.04,0.18,0.04,0.28H55.09z"/>
\t\t<path class="st1" d="M59.23,61.79c-0.02-0.06-0.06-0.11-0.1-0.15c-0.04-0.04-0.09-0.08-0.15-0.1c-0.06-0.02-0.12-0.04-0.19-0.04
\t\t\tc-0.07,0-0.13,0.01-0.19,0.04c-0.06,0.03-0.11,0.06-0.15,0.1c-0.04,0.04-0.08,0.09-0.1,0.15c-0.02,0.06-0.04,0.12-0.04,0.19
\t\t\tc0,0.07,0.01,0.13,0.04,0.19c0.02,0.06,0.06,0.11,0.1,0.15c0.04,0.04,0.09,0.08,0.15,0.1c0.06,0.02,0.12,0.04,0.19,0.04
\t\t\tc0.06,0,0.13-0.01,0.19-0.04c0.06-0.02,0.11-0.06,0.15-0.1c0.04-0.04,0.08-0.09,0.1-0.15c0.02-0.06,0.04-0.12,0.04-0.19
\t\t\tC59.27,61.91,59.26,61.85,59.23,61.79z"/>
\t\t<path class="st1" d="M65.3,58.53v-0.67h-2.87v4.54h2.87v-0.68h-2.01v-1.29h1.59v-0.65h-1.59v-1.26H65.3z"/>
\t\t<path class="st1" d="M69.06,59.87c-0.05-0.15-0.13-0.28-0.23-0.39s-0.23-0.2-0.38-0.26c-0.15-0.06-0.32-0.09-0.5-0.09
\t\t\tc-0.52,0-0.97,0.17-1.34,0.51l0.14,0.25c0.02,0.04,0.05,0.07,0.09,0.1c0.04,0.03,0.08,0.04,0.13,0.04c0.06,0,0.12-0.02,0.17-0.04
\t\t\tc0.05-0.03,0.11-0.06,0.17-0.1c0.06-0.04,0.13-0.07,0.22-0.1c0.08-0.03,0.19-0.04,0.31-0.04c0.17,0,0.31,0.05,0.4,0.16
\t\t\tc0.09,0.11,0.14,0.27,0.14,0.48v0.19c-0.37,0-0.67,0.03-0.92,0.09c-0.25,0.06-0.45,0.14-0.59,0.24c-0.15,0.1-0.26,0.2-0.32,0.32
\t\t\tc-0.06,0.12-0.1,0.24-0.1,0.36c0,0.15,0.02,0.27,0.07,0.38c0.05,0.11,0.11,0.2,0.19,0.27c0.08,0.07,0.18,0.13,0.3,0.17
\t\t\tc0.12,0.04,0.24,0.05,0.38,0.05c0.12,0,0.22-0.01,0.31-0.03c0.09-0.02,0.18-0.05,0.27-0.08c0.08-0.04,0.16-0.09,0.24-0.14
\t\t\tc0.08-0.06,0.16-0.12,0.24-0.19l0.07,0.23c0.02,0.07,0.05,0.11,0.09,0.13c0.04,0.02,0.1,0.03,0.17,0.03h0.35v-2.04
\t\t\tC69.14,60.19,69.11,60.02,69.06,59.87z M68.38,61.58c-0.06,0.06-0.11,0.11-0.17,0.15c-0.05,0.04-0.11,0.08-0.17,0.11
\t\t\tc-0.06,0.03-0.12,0.05-0.19,0.07c-0.07,0.01-0.14,0.02-0.22,0.02c-0.13,0-0.23-0.03-0.32-0.09c-0.08-0.06-0.12-0.16-0.12-0.29
\t\t\tc0-0.07,0.02-0.13,0.06-0.19c0.04-0.06,0.1-0.11,0.2-0.16c0.09-0.05,0.22-0.08,0.37-0.11c0.15-0.03,0.34-0.04,0.57-0.04V61.58z"/>
\t\t<path class="st1" d="M72.59,61.05c-0.05-0.09-0.1-0.17-0.18-0.24c-0.07-0.07-0.16-0.12-0.25-0.16c-0.09-0.04-0.19-0.08-0.29-0.12
\t\t\tc-0.1-0.03-0.19-0.06-0.29-0.09c-0.09-0.03-0.18-0.06-0.25-0.1c-0.07-0.04-0.13-0.08-0.18-0.12c-0.05-0.05-0.07-0.1-0.07-0.17
\t\t\tc0-0.1,0.04-0.19,0.13-0.25c0.09-0.07,0.21-0.1,0.36-0.1c0.1,0,0.18,0.01,0.26,0.03c0.07,0.02,0.14,0.04,0.19,0.07
\t\t\tc0.06,0.03,0.11,0.05,0.15,0.07c0.04,0.02,0.08,0.03,0.12,0.03c0.03,0,0.06-0.01,0.09-0.02c0.02-0.01,0.05-0.04,0.07-0.07
\t\t\tl0.18-0.28c-0.12-0.12-0.27-0.22-0.46-0.29c-0.18-0.07-0.38-0.11-0.61-0.11c-0.2,0-0.37,0.03-0.53,0.08
\t\t\tc-0.15,0.05-0.28,0.12-0.38,0.21c-0.1,0.09-0.18,0.19-0.23,0.31c-0.05,0.12-0.08,0.25-0.08,0.38c0,0.14,0.02,0.26,0.07,0.36
\t\t\tc0.04,0.1,0.1,0.19,0.18,0.26c0.07,0.07,0.16,0.13,0.26,0.17c0.09,0.05,0.19,0.09,0.29,0.12c0.1,0.03,0.2,0.06,0.29,0.09
\t\t\tc0.09,0.03,0.18,0.06,0.25,0.1c0.07,0.04,0.13,0.08,0.18,0.12c0.05,0.05,0.07,0.11,0.07,0.19c0,0.05-0.01,0.1-0.03,0.15
\t\t\tc-0.02,0.05-0.05,0.09-0.09,0.12c-0.04,0.04-0.1,0.06-0.16,0.09c-0.07,0.02-0.15,0.03-0.24,0.03c-0.12,0-0.22-0.01-0.29-0.04
\t\t\tc-0.08-0.03-0.14-0.06-0.2-0.09c-0.06-0.03-0.11-0.06-0.15-0.09c-0.04-0.03-0.09-0.04-0.14-0.04c-0.05,0-0.09,0.01-0.12,0.03
\t\t\tc-0.03,0.02-0.06,0.05-0.08,0.08l-0.18,0.29c0.06,0.06,0.13,0.11,0.22,0.16c0.08,0.05,0.17,0.09,0.27,0.13
\t\t\tc0.1,0.03,0.2,0.06,0.3,0.08c0.11,0.02,0.21,0.03,0.33,0.03c0.21,0,0.39-0.03,0.55-0.08c0.16-0.05,0.29-0.13,0.4-0.22
\t\t\tc0.11-0.1,0.19-0.21,0.25-0.34c0.06-0.13,0.09-0.27,0.09-0.43C72.66,61.25,72.64,61.14,72.59,61.05z"/>
\t\t<path class="st1" d="M76.19,59.18c-0.05,0-0.09,0.01-0.13,0.04c-0.04,0.03-0.07,0.06-0.08,0.1l-0.65,1.67
\t\t\tc-0.02,0.06-0.04,0.12-0.06,0.17c-0.02,0.06-0.04,0.12-0.05,0.18c-0.01-0.06-0.03-0.12-0.04-0.18S75.13,61.05,75.1,61l-0.69-1.67
\t\t\tc-0.02-0.04-0.04-0.07-0.08-0.1c-0.03-0.03-0.08-0.04-0.14-0.04h-0.68l1.3,2.99l-0.6,1.29h0.58c0.08,0,0.13-0.02,0.17-0.04
\t\t\tc0.04-0.03,0.07-0.07,0.09-0.12l1.76-4.11H76.19z"/>
\t</g>
\t<g>
\t\t<path class="st1" d="M9.03,49.72c-0.07-0.02-0.13-0.04-0.2-0.04c-0.08,0-0.16,0.02-0.24,0.05c-0.08,0.04-0.15,0.08-0.22,0.14
\t\t\tc-0.17,0.14-0.33,0.26-0.49,0.36c-0.16,0.1-0.34,0.18-0.51,0.24c-0.18,0.06-0.38,0.1-0.59,0.13c-0.21,0.03-0.44,0.04-0.7,0.04
\t\t\tc-0.44,0-0.86-0.09-1.25-0.26s-0.73-0.42-1.02-0.75c-0.29-0.33-0.52-0.74-0.69-1.22c-0.17-0.48-0.25-1.03-0.25-1.65
\t\t\tc0-0.59,0.08-1.13,0.25-1.6c0.17-0.48,0.4-0.88,0.71-1.22c0.3-0.34,0.66-0.59,1.08-0.77c0.42-0.18,0.89-0.27,1.4-0.27
\t\t\tc0.29,0,0.55,0.02,0.77,0.07c0.22,0.04,0.41,0.1,0.57,0.16c0.16,0.06,0.31,0.13,0.42,0.21c0.12,0.07,0.23,0.14,0.33,0.21
\t\t\tc0.1,0.06,0.19,0.12,0.28,0.16c0.09,0.04,0.18,0.07,0.28,0.07c0.15,0,0.27-0.03,0.35-0.1c0.08-0.06,0.16-0.13,0.22-0.21l0.95-1.27
\t\t\tc-0.23-0.24-0.49-0.46-0.78-0.65c-0.29-0.2-0.61-0.37-0.96-0.51c-0.35-0.14-0.72-0.25-1.13-0.33c-0.4-0.08-0.83-0.12-1.28-0.12
\t\t\tc-0.94,0-1.79,0.15-2.57,0.46c-0.78,0.31-1.44,0.73-2,1.28c-0.56,0.55-0.99,1.2-1.3,1.96C0.16,45.03,0,45.86,0,46.76
\t\t\tc0,0.89,0.14,1.71,0.41,2.46c0.27,0.76,0.66,1.41,1.17,1.96c0.51,0.55,1.13,0.98,1.85,1.3c0.73,0.31,1.55,0.47,2.47,0.47
\t\t\tc1,0,1.87-0.16,2.61-0.49c0.73-0.32,1.34-0.81,1.83-1.44l-1.11-1.17C9.16,49.79,9.1,49.75,9.03,49.72z"/>
\t\t<path class="st1" d="M22.65,40.52H19.7l-4.81,12.43h2.25c0.24,0,0.44-0.06,0.6-0.19c0.17-0.13,0.28-0.27,0.33-0.43l0.73-2.17h4.73
\t\t\tl0.73,2.17c0.06,0.18,0.17,0.33,0.34,0.45c0.16,0.12,0.37,0.17,0.62,0.17h2.24L22.65,40.52z M19.47,48.2l1.14-3.39
\t\t\tc0.08-0.22,0.18-0.47,0.27-0.77c0.1-0.3,0.19-0.62,0.28-0.96c0.1,0.34,0.2,0.66,0.3,0.95c0.1,0.29,0.19,0.55,0.26,0.76l1.14,3.4
\t\t\tH19.47z"/>
\t\t<path class="st1" d="M40.2,48.62c-0.12-0.2-0.27-0.38-0.44-0.52c-0.17-0.14-0.35-0.26-0.54-0.36c0.4-0.15,0.76-0.35,1.07-0.59
\t\t\tc0.31-0.24,0.57-0.51,0.78-0.81c0.21-0.3,0.37-0.64,0.48-0.99c0.11-0.36,0.16-0.74,0.16-1.14c0-0.53-0.09-1.02-0.27-1.47
\t\t\tc-0.18-0.45-0.47-0.84-0.87-1.17c-0.4-0.33-0.92-0.59-1.55-0.77c-0.63-0.19-1.4-0.28-2.3-0.28h-4.04v12.43h2.88v-4.6h0.73
\t\t\tc0.22,0,0.39,0.03,0.52,0.1c0.12,0.07,0.23,0.18,0.32,0.34l2.06,3.61c0.21,0.36,0.56,0.55,1.04,0.55h2.61L40.2,48.62z
\t\t\t M38.78,45.18c-0.08,0.24-0.21,0.44-0.38,0.62c-0.18,0.18-0.4,0.31-0.68,0.41c-0.27,0.1-0.61,0.15-1,0.15h-1.16v-3.68h1.16
\t\t\tc0.74,0,1.3,0.15,1.65,0.46c0.36,0.31,0.53,0.73,0.53,1.28C38.9,44.69,38.86,44.95,38.78,45.18z"/>
\t\t<path class="st0" d="M63.26,40.58c-0.14,0-0.27,0.04-0.37,0.12c-0.1,0.08-0.17,0.17-0.2,0.28l-2.56,8.6
\t\t\tc-0.05,0.15-0.08,0.31-0.11,0.48c-0.03,0.17-0.06,0.35-0.1,0.54c-0.08-0.38-0.17-0.73-0.28-1.03l-2.89-8.6
\t\t\tc-0.04-0.12-0.11-0.21-0.2-0.28c-0.09-0.07-0.22-0.11-0.37-0.11h-0.48c-0.14,0-0.27,0.04-0.37,0.12c-0.1,0.08-0.17,0.17-0.2,0.27
\t\t\tl-2.91,8.6c-0.05,0.15-0.1,0.32-0.14,0.5c-0.04,0.18-0.08,0.37-0.12,0.57c-0.04-0.2-0.07-0.38-0.11-0.56
\t\t\tc-0.04-0.18-0.08-0.35-0.12-0.5l-2.56-8.6c-0.03-0.12-0.1-0.21-0.2-0.28c-0.1-0.07-0.22-0.11-0.37-0.11h-1.39l3.86,12.37h1.5
\t\t\tl3.15-9.44c0.03-0.09,0.06-0.18,0.08-0.29c0.03-0.11,0.05-0.22,0.08-0.33c0.05,0.24,0.11,0.44,0.17,0.62l3.13,9.44h1.5l3.86-12.37
\t\t\tH63.26z"/>
\t\t<path class="st0" d="M70.29,40.58v12.37h1.67V40.58H70.29z"/>
\t\t<path class="st0" d="M86,47.88c-0.17-0.34-0.39-0.63-0.66-0.87c-0.27-0.24-0.58-0.43-0.93-0.59c-0.35-0.16-0.7-0.29-1.06-0.42
\t\t\tc-0.36-0.12-0.72-0.24-1.06-0.36c-0.35-0.12-0.65-0.25-0.93-0.41c-0.27-0.16-0.49-0.35-0.66-0.57c-0.17-0.22-0.25-0.5-0.25-0.84
\t\t\tc0-0.26,0.05-0.51,0.14-0.74c0.1-0.23,0.24-0.43,0.43-0.59c0.19-0.17,0.42-0.3,0.7-0.4c0.28-0.1,0.61-0.15,0.97-0.15
\t\t\tc0.39,0,0.72,0.05,0.99,0.14c0.27,0.09,0.51,0.2,0.7,0.32c0.19,0.12,0.35,0.22,0.48,0.32c0.13,0.1,0.24,0.14,0.33,0.14
\t\t\tc0.09,0,0.16-0.02,0.22-0.06c0.06-0.04,0.11-0.11,0.16-0.19l0.4-0.78c-0.42-0.4-0.9-0.71-1.46-0.93
\t\t\tc-0.56-0.21-1.17-0.32-1.85-0.32c-0.6,0-1.13,0.09-1.6,0.28c-0.46,0.19-0.85,0.43-1.17,0.74c-0.32,0.31-0.56,0.66-0.72,1.06
\t\t\tc-0.17,0.4-0.25,0.81-0.25,1.24c0,0.53,0.08,0.98,0.25,1.35c0.17,0.36,0.38,0.67,0.65,0.91c0.27,0.24,0.58,0.44,0.92,0.59
\t\t\tc0.35,0.15,0.7,0.29,1.06,0.4c0.36,0.12,0.72,0.23,1.06,0.34c0.35,0.11,0.65,0.24,0.92,0.4c0.27,0.16,0.49,0.35,0.65,0.59
\t\t\tc0.17,0.23,0.25,0.53,0.25,0.9c0,0.33-0.06,0.63-0.17,0.9c-0.11,0.27-0.27,0.5-0.49,0.7c-0.21,0.19-0.47,0.34-0.77,0.45
\t\t\tc-0.3,0.11-0.65,0.16-1.04,0.16c-0.47,0-0.86-0.06-1.19-0.19c-0.32-0.13-0.6-0.27-0.83-0.42c-0.23-0.15-0.42-0.29-0.57-0.42
\t\t\ts-0.29-0.19-0.4-0.19c-0.08,0-0.15,0.02-0.22,0.06c-0.06,0.04-0.12,0.09-0.16,0.16l-0.47,0.78c0.46,0.48,1,0.87,1.65,1.15
\t\t\tc0.64,0.28,1.36,0.43,2.14,0.43c0.64,0,1.21-0.1,1.72-0.3c0.5-0.2,0.93-0.47,1.27-0.81c0.35-0.34,0.61-0.75,0.79-1.21
\t\t\tc0.18-0.46,0.27-0.96,0.27-1.49C86.25,48.64,86.16,48.22,86,47.88z"/>
\t\t<path class="st0" d="M100,41.96v-1.37h-7.65V53H100v-1.37h-5.96v-4.24h4.82v-1.32h-4.82v-4.12H100z"/>
\t</g>
</g>
</svg>
`]
