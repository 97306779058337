import React, {Component} from 'react';
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import './scss/style.scss';
import store from "./store";


const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const LoginAdmin = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {
  constructor(props) {
    super(props);
    let role = localStorage.getItem('userName');
    store.dispatch({type : 'set',role : role})
    this.state = {token: '', role: role};
  }

  setToken = (userToken) => {
    this.updateToken(userToken)
  }

// componentWillUpdate(nextProps, nextState, nextContext) {
//     if(nextState.token !== this.state.token){
//       this.setToken({
//         token : nextState.token,
//         isLoggedIn:true,
//       })
//     }
//     console.log(nextState)
//     console.log(this.state)
// }

  componentDidMount() {

  }

  getToken() {
    const userToken = sessionStorage.getItem('token');
    //const userToken =  tokenString ? JSON.parse(tokenString) : '';
    return userToken
  }

  updateToken = (userToken) => {
    console.log(userToken)
    this.setState({
      token: userToken,
      isLoggedIn: true
    }, () => {
      sessionStorage.setItem('token', userToken)
    })
  }

  render() {
    const token = this.getToken();
    console.log(token)
    return (
      <HashRouter>
        <React.Suspense fallback={loading}>
          {(!token) ?
            <Redirect to="/login"/> : ""
          }
          {
            this.state.isLoggedIn ? <Redirect to="/evaluations"/> : !token ? <Redirect to="/login"/> : ""
          }
          <Switch>

            <Route exact path="/login" name="Login Page"
                   render={props => <LoginAdmin {...props} setToken={this.setToken}/>}/>
            <Route path="/" name="Home" render={props => <TheLayout {...props}/>}/>
            <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>}/>
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>}/>
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>}/>
            <Route path="/" name="Home" render={props => <TheLayout {...props}/>}/>
          </Switch>
        </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
